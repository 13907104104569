import { Component, OnInit, PipeTransform, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DecimalPipe } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ViewProduct } from '../../_models/index';
import { PageService, AdminProductService } from '../../_services/index';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
@Component({
    selector: 'app-admin-products',
    templateUrl: './adminProducts.component.html',
    styleUrls: ['./adminProducts.component.scss'],
    providers: [DecimalPipe],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})


export class AdminProductsComponent implements OnInit {
    faTimes = faTimes;
    faCheck = faCheck;
    products$: Observable<ViewProduct[]>;
    filter = new UntypedFormControl('');
    allProducts: ViewProduct[] = [];
    public loading = false;
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private router: Router,
        private title: Title,
        private pipe: DecimalPipe,
        private pageService: PageService,
        private adminProductService: AdminProductService) {
    }

    search(text: string, pipe: PipeTransform): ViewProduct[] {
        return this.allProducts.filter(product => {
            const term = text.toLowerCase();
            return product.name.toLowerCase().includes(term)
                || product.categoryName.toLowerCase().includes(term)
                || product.manufacturerName.toLowerCase().includes(term);
        });
    }

    ngOnInit() {
        this.title.setTitle('Solent Wholesale | Admin || Products');
        this.getProducts();
    }

    getProducts() {
        this.loading = true;
        this.pageService.getAdminProducts().pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(products => this.setProducts(products));
    }

    setProducts(products: ViewProduct[]) {
        this.loading = false;
        this.allProducts = products;
        this.products$ = this.filter.valueChanges.pipe(
            startWith(''),
            map(text => this.search(text, this.pipe))
        );
    }

    evalAPIResponse(response) {
        if (response.status === true) {
            this.getProducts();
        }
    }

    markProductClearance(id) {
        this.adminProductService.markProductClearance(id).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }

    markProductHidden(id) {
        this.adminProductService.markProductHidden(id).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }

    editProduct(productID) {
        this.router.navigate(['/admin/product_add/', productID]);
        // const newURL = '/admin/product_add/' + productID;
        // this.router.navigateByUrl(newURL);
    }

    manageExtras(productID) {
        this.router.navigate(['/admin/extras/', productID]);
    }

}
