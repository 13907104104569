import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';
import { BlogPost } from '../../_models/index';
import { PageService, AdminBlogService } from '../../_services/index';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss'],
    standalone: false
})
export class BlogComponent implements OnInit, OnDestroy {
    faTimes = faTimes;
    faCheck = faCheck;
    posts: BlogPost[] = [];
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private pageService: PageService,
        private adminBlogService: AdminBlogService,
        private router: Router) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.getPosts();
    }

    getPosts() {
        this.pageService.getAllBlogPosts().pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.posts = x);
    }

    addNewPost(postSlug) {
        if (postSlug === null) {
            // Cannot pass normal null values to router
            postSlug = 'null';
        }
        this.router.navigate(['/admin/add_blog_post', postSlug]);
    }

    publishPost(postID) {
        this.adminBlogService.publishPost(postID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.getPosts());
    }

}
