import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ColourType, ProductCategory } from '../../../_models/index';
import { PageService, AdminProductService } from '../../../_services/index';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
@Component({
    selector: 'app-colourtypes',
    templateUrl: './colourtypes.component.html',
    styleUrls: ['./colourtypes.component.scss'],
    standalone: false
})
export class ColourtypesComponent implements OnInit, OnDestroy {
    categories: ProductCategory[] = [];
    colourTypes: ColourType[] = [];
    colourTypesQ: ColourType[] = [];
    formError = null;
    faCircle = faCircle;
    editForm = false;
    selectedColour;
    colourTypeForm = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        name: new UntypedFormControl(null, Validators.required),
        hexValue: new UntypedFormControl(null, Validators.required),
        categoryID: new UntypedFormControl(null, Validators.required)
    });

    private modalRefService: NgbModalRef;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    @ViewChild('addCTModal', { static: true }) private addCTModal;


    constructor(
        private pageService: PageService,
        private adminProductService: AdminProductService,
        private modalService: NgbModal) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.fetchData();
    }

    fetchData () {
        this.pageService.getCategories(false).pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.categories = x);

        this.pageService.getColourTypes(null).pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.colourTypes = x);
    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.editForm = false;
            this.colourTypeForm.reset();
            this.formError = null;
            this.selectedColour = null;
            this.modalService.dismissAll();
            this.fetchData();
        } else {
            this.formError = response.message;
        }

    }


    addCT() {
        this.editForm = false;
        this.selectedColour = null;
        this.colourTypeForm.reset();
        this.modalRefService = this.modalService.open(this.addCTModal);
    }

    editCT(id) {
        this.editForm = true;
        this.colourTypesQ = this.colourTypes.filter(x => x.id === id);
        this.selectedColour = this.colourTypesQ[0].hexValue;
        this.colourTypeForm.controls.id.setValue(this.colourTypesQ[0].id);
        this.colourTypeForm.controls.name.setValue(this.colourTypesQ[0].name);
        this.colourTypeForm.controls.hexValue.setValue(this.colourTypesQ[0].hexValue);
        this.colourTypeForm.controls.categoryID.setValue(this.colourTypesQ[0].categoryID);
        // open modal
        this.modalRefService = this.modalService.open(this.addCTModal);
    }

    updateColour(value) {
        this.selectedColour = value;
        this.colourTypeForm.controls.hexValue.setValue(value);
    }

    saveNewCT() {
        const newColourType: ColourType = {
            id: this.colourTypeForm.controls.id.value,
            name: this.colourTypeForm.controls.name.value,
            hexValue: this.colourTypeForm.controls.hexValue.value,
            categoryID: this.colourTypeForm.controls.categoryID.value,
        };

        if (this.editForm === false) {
            this.adminProductService.newColourType(newColourType).pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(response => this.evalAPIResponse(response));
        } else {
            this.adminProductService.editColourType(newColourType).pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(response => this.evalAPIResponse(response));
        }


    }

}
