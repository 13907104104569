import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-multi-image-upload',
    templateUrl: './multi-image-upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: MultiImageUploadComponent,
            multi: true
        }
    ],
    styleUrls: ['./multi-image-upload.component.scss'],
    standalone: false
})

export class MultiImageUploadComponent implements ControlValueAccessor {
  @Input() progress;
  files: File[];
  onChange: (_) => void;

  @HostListener('change', ['$event.target.files']) emitFiles(fileList: FileList) {
    this.files = Array.from(fileList);
    this.onChange(this.files);
  }

  constructor(private host: ElementRef<HTMLInputElement>) { }

  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = '';
    this.files = [];
  }

  registerOnChange(cb) {
    this.onChange = cb;
  }

  registerOnTouched(cb) { }
}



