import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { takeUntil, take } from 'rxjs/operators';
import { filter, map, tap } from 'rxjs/operators';
import { Subject, pipe } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProductCategory } from '../../../_models/index';
import { PageService, AdminCategoryService } from '../../../_services/index';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { API_URL } from '../../../app.const';

export function uploadProgress<T> (cb: ( progress: number ) => void ) {
    return tap(( event: HttpEvent<T> ) => {
        if ( event.type === HttpEventType.UploadProgress ) {
            cb(Math.round((100 * event.loaded) / event.total));
        }
    });
}

export function markAllAsDirty (form: UntypedFormGroup ) {
    for ( const control of Object.keys(form.controls) ) {
        form.controls[control].markAsDirty();
    }
}

export function toFormData<T> (formValue: T ) {
    const formData = new FormData();
    for ( const key of Object.keys(formValue) ) {
        const value = formValue[key];
        formData.append(key, value);
    }

    return formData;
}

export function toResponseBody<T> () {
    return pipe(
        filter(( event: HttpEvent<T> ) => event.type === HttpEventType.Response),
        map(( res: HttpResponse<T> ) => res.body)
    );
}


@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
    standalone: false
})
export class CategoriesComponent implements OnInit, OnDestroy {
    categories: ProductCategory[] = [];
    categoryQ = null;
    progress = 0;
    success = false;
    formError = null;
    categoryForm = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        name: new UntypedFormControl(null, Validators.required),
        position: new UntypedFormControl(null, Validators.required),
        image: new UntypedFormControl(null, Validators.required)
    });

    private modalRefService: NgbModalRef;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    @ViewChild('addCategoryModal', { static: true }) private addCategoryModal;
    @ViewChild('editCategoryModal', { static: true }) private editCategoryModal;

    constructor (
        private pageService: PageService,
        private adminCategoryService: AdminCategoryService,
        private fb: UntypedFormBuilder,
        private modalService: NgbModal,
        private ref: ChangeDetectorRef,
        private http: HttpClient) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit () {
        this.getCateogires();
    }

    getCateogires () {
        this.pageService.getCategories(true).pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.categories = x);
    }

    setCategories (categories) {
        this.categories = categories;
    }

    addCategory () {
        // open modal
        this.categoryForm.reset();
        this.modalRefService = this.modalService.open(this.addCategoryModal);
    }


    saveNewCategory () {
        const uploadURL = API_URL + '/admin/products/add_category';

        this.success = false;
        if ( !this.categoryForm.valid ) {
            markAllAsDirty(this.categoryForm);
            return;
        }

        this.http.post(uploadURL, toFormData(this.categoryForm.value), {
            reportProgress: true,
            observe: 'events',
        }).pipe(
            takeUntil(this.ngUnsubscribe),
            uploadProgress(progress => (this.progress = progress)),
            toResponseBody()
        ).subscribe(res => {
            this.evalAPIResponse(res);
        });
    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.progress = 0;
            this.success = true;
            this.categoryForm.reset();
            this.formError = null;
            this.modalService.dismissAll();
            this.getCateogires();
        } else {
            this.formError = response.message;
        }

    }

    editCategory (id) {
        this.categoryQ = this.categories.filter(x => x.id === id);
        this.categoryForm.controls.id.setValue(this.categoryQ[0].id);
        this.categoryForm.controls.name.setValue(this.categoryQ[0].name);
        this.categoryForm.controls.position.setValue(this.categoryQ[0].position);
        // open modal
        this.modalRefService = this.modalService.open(this.editCategoryModal);
    }

    removeImage (id) {
        // We need to remove the image, send request to API
        this.adminCategoryService.removeCategoryImage(id).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }

    updateCategoryImage (id) {
        // Assign a new image to a category
        const uploadURL = API_URL + '/admin/products/assign_category_image';
        this.success = false;
        if ( !this.categoryForm.valid ) {
            markAllAsDirty(this.categoryForm);
            return;
        }

        this.http.post(uploadURL, toFormData(this.categoryForm.value), {
            reportProgress: true,
            observe: 'events',
        }).pipe(
            takeUntil(this.ngUnsubscribe),
            uploadProgress(progress => (this.progress = progress)),
            toResponseBody()
        ).subscribe(res => {
            this.evalAPIResponse(res);
        });

    }

    updateCategory (id) {
        const name = this.categoryForm.controls.name.value,
            position = this.categoryForm.controls.position.value;

        this.adminCategoryService.updateCategory(id, name, position).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));

    }

    markCategoryHidden (id) {
        this.adminCategoryService.markCategoryHidden(id).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }
}
