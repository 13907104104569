import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-single-image-upload',
    templateUrl: './single-image-upload.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SingleImageUploadComponent,
            multi: true
        }
    ],
    styleUrls: ['./single-image-upload.component.scss'],
    standalone: false
})
export class SingleImageUploadComponent implements ControlValueAccessor {
    @Input() progress;
    onChange: Function;
    private file: File | null = null;

    constructor ( private host: ElementRef<HTMLInputElement> ) {}

    @HostListener('change', ['$event.target.files']) emitFiles (event: FileList) {
        const file = event && event.item(0);
        this.onChange(file);
        this.file = file;
    }


    writeValue (value: null) {
        // clear file input
        this.host.nativeElement.value = '';
        this.file = null;
    }

    registerOnChange (fn: Function) {
        this.onChange = fn;
    }

    registerOnTouched (fn: Function) {
    }

}
