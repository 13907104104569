<section class="event team-sec speaker set-relative" id="speaker">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-12 speker-container">

            <div *ngIf="formError">
                <div class="alert alert-danger" role="alert">
                        <h1>{{formError}}</h1>
                </div>
            </div> 

            <form class="form">
                <div class="form-group" [formGroup]="productForm">
	                <label for="name">Name</label>
	                <div class="input-group">
	                <input class="form-control" placeholder="Product Name"
	                    id="name"
	                    type="text"
	                    formControlName="name">
	                </div>
	                <p *ngIf="productForm.get(['name']).hasError('required')" 
		                style="color:red;">A name is required</p>
	                

	                <!-- Product Category -->
	                <label for="cat_select">Category</label>
		            <select class="form-control"
		                    formControlName="categoryID">
		            <option disabled selected value> --Select-- </option>
		            <option *ngFor="let cat of categories" [ngValue]="cat.id" >{{cat.name}}</option>
		            </select>
		            <p *ngIf="productForm.get(['categoryID']).hasError('required')" 
		                style="color:red;">You must select a category</p>
		            <br />

		            <!-- Description -->
		            <label for="name">Description</label>
	                <div class="input-group">
	                <input class="form-control" placeholder="Description"
	                    id="Description"
	                    type="text"
	                    formControlName="briefDescription">
	                </div>
	                <p *ngIf="productForm.get(['briefDescription']).hasError('required')" 
		                style="color:red;">You must describe the product</p>
		            <p *ngIf="productForm.get(['briefDescription']).hasError('maxlength')" 
		                style="color:red;">Description is to long</p>
	                <br />

	                <!-- Manufacturer -->
	                <label for="man_select">Manufacturer</label>
		            <select class="form-control"
		                    formControlName="manufacturerID">
		            <option disabled selected value> --Select-- </option>
		            <option *ngFor="let man of manufacturers" [ngValue]="man.id" >{{man.name}}</option>
		            </select>
		            <p *ngIf="productForm.get(['manufacturerID']).hasError('required')" 
		                style="color:red;">You must select a manufacturer</p>
		            <br />

		            <!-- YouTube -->
		            <label for="name">YouTube</label>
	                <div class="input-group">
	                <input class="form-control" placeholder="YouTube video link"
	                    id="youtube"
	                    type="text"
	                    formControlName="youtube">
	                </div>
	                <br />

	                <!-- Clearance -->
		            <label for="clearance">Clearance</label>
	                <div class="form-check">
		                
		                <input id="clearance" class="form-check-input" type="checkbox" formControlName="clearance">
	                </div>

	                <br />

	                <!-- Hidden -->
		            <label for="hidden">Hidden</label>
	                <div class="form-check">
		                <input id="hidden" class="form-check-input" type="checkbox" formControlName="hidden">
	                </div>

	                <br />

	                <!-- Express -->
		            <label for="clearance">Express</label>
	                <div class="form-check">
		                
		                <input id="express" class="form-check-input" type="checkbox" formControlName="express">
	                </div>

	                <br />


	               

	                <br />
	                <!--   -->
                    <button class="btn btn-success" [disabled]="productForm?.invalid"
                    (click)="saveNewProduct()">Save</button>
                    

                
                </div>
            </form>


            </div>

            <div class="col-md-6 col-sm-12 speker-container">
            	<form class="form">
	                <div class="form-group" [formGroup]="attributeForm">
	            	<!-- Manufacturer -->
	                <label for="man_select">Attributes</label>
		            <select class="form-control"
		                    formControlName="id">
		            <option disabled selected value> --Select-- </option>
		            <option *ngFor="let att of attributes" [ngValue]="att.id" >{{att.name}}</option>
		            </select>
		            <br />

                    <button class="btn btn-info" (click)="addAttribute()">Add Attribute</button>
                    <br /><br />
			        	

			        <div *ngFor="let aid of attributeIDS">
			        	<!-- Now we need to render a form control for each attribute -->
			            <label for="name">{{idToAttName(aid)}} -- 
			            	<fa-icon (click)="removeAttribute(aid)" 
			            		class="fa-lg redFA" size="lg" [icon]="faTimes"></fa-icon>
			            </label>
		                <div class="input-group">
		                <input class="form-control" placeholder="{{idToAttName(aid)}}"
		                    id="{{aid}}"
		                    type="text"
		                    formControlName="{{aid}}">
		                </div>
		                <p *ngIf="attributeForm.get([aid]).hasError('required')" 
			                style="color:red;">You must complete the attribute</p>
		                <br />
			        </div>
			        </div>
			    </form>

                <div>
                    <p class="sectorTitle">Assigned Sectors</p>

                    <button *ngFor="let sector of assignedSectors" 
                            class="btn btn-success" (click)="removeSector(sector)">
                                {{sector.sector}}
                    </button>
                </div>

                <div>
                    <p class="sectorTitle">Other Sectors</p>
                    <button *ngFor="let sector of freeSectors" 
                            class="btn btn-danger" (click)="addSector(sector)">
                                {{sector.sector}}
                    </button>
                </div>

            </div>

          
        </div>
    </div>
</section>