import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Carrier } from '../../_models/index';
import { AdminExpressService } from '../../_services/index';
import { takeUntil } from 'rxjs/operators';
@Component({
    selector: 'app-carriers',
    templateUrl: './carriers.component.html',
    styleUrls: ['./carriers.component.scss'],
    standalone: false
})
export class CarriersComponent implements OnInit, OnDestroy {
    carriers: Carrier[];
    formError;

    carrierForm = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        name: new UntypedFormControl(null, Validators.required),
        trackingPrefix: new UntypedFormControl(null, Validators.required),
        trackingExample: new UntypedFormControl(null, Validators.required)
    });

    private modalRefService: NgbModalRef;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    @ViewChild('EditCarrier', { static: true }) private editCarrierModal;

    constructor(
        private adminExpress: AdminExpressService,
        private modalService: NgbModal) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit(): void {
        this.fetchData();
    }

    fetchData() {
        this.adminExpress.getCarriers().subscribe(
            carriers => this.carriers = carriers);
    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.carrierForm.reset();
            this.formError = null;
            this.modalService.dismissAll();
            this.fetchData();
        } else {
            this.formError = response.message;
        }

    }


    editCarrier(carrier: Carrier) {
        this.carrierForm.reset();
        this.carrierForm.controls.id.setValue(carrier.id);
        this.carrierForm.controls.name.setValue(carrier.name);
        this.carrierForm.controls.trackingPrefix.setValue(carrier.trackingPrefix);
        this.carrierForm.controls.trackingExample.setValue(carrier.exampleTrackingId);
        this.modalRefService = this.modalService.open(this.editCarrierModal);
    }

    updateCarrier() {
        // Update details with API
        const carrierID =  this.carrierForm.controls.id.value;
        const carrierName =  this.carrierForm.controls.name.value;
        const carrierTrackingPrefix =  this.carrierForm.controls.trackingPrefix.value;
        const trackingExampleID =  this.carrierForm.controls.trackingExample.value;

        this.adminExpress.updateCarrier(
            carrierID, carrierName, carrierTrackingPrefix, trackingExampleID)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }

}
