import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { filter, map, tap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BlogPost } from '../../../_models/index';
import { PageService, AdminBlogService, AuthenticationService } from '../../../_services/index';
import { Router, ActivatedRoute, } from '@angular/router';
import { pipe } from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { API_URL } from '../../../app.const';
import { JwtHelperService } from '@auth0/angular-jwt';


export function uploadProgress<T> (cb: ( progress: number ) => void ) {
    return tap(( event: HttpEvent<T> ) => {
        if ( event.type === HttpEventType.UploadProgress ) {
            cb(Math.round((100 * event.loaded) / event.total));
        }
    });
}

export function markAllAsDirty (form: UntypedFormGroup ) {
    for ( const control of Object.keys(form.controls) ) {
        form.controls[control].markAsDirty();
    }
}

export function toFormData<T> (formValue: T ) {
    const formData = new FormData();
    for ( const key of Object.keys(formValue) ) {
        const value = formValue[key];
        formData.append(key, value);
    }

    return formData;
}

export function toResponseBody<T> () {
    return pipe(
        filter(( event: HttpEvent<T> ) => event.type === HttpEventType.Response),
        map(( res: HttpResponse<T> ) => res.body)
    );
}

@Component({
    selector: 'app-create-post',
    templateUrl: './create-post.component.html',
    styleUrls: ['./create-post.component.scss'],
    standalone: false
})
export class CreatePostComponent implements OnInit, OnDestroy {
    progress = 0;
    success = false;
    formError = null;
    browserTest = false;
    blogID;
    blogPostQ: BlogPost = null;

    blogForm = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        userName: new UntypedFormControl(null, Validators.required),
        name: new UntypedFormControl(null, Validators.required),
        wysiwyg: new UntypedFormControl(null, Validators.required),
        image: new UntypedFormControl(null, Validators.required),
        tags: new UntypedFormControl(null, Validators.required),
    });

    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private jwtHelper: JwtHelperService = new JwtHelperService();

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private http: HttpClient,
        private adminBlogService: AdminBlogService,
        private pageService: PageService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthenticationService) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        const userName = this.authService.returnUserName();
        this.blogForm.controls.userName.setValue(userName);
        this.blogID = this.route.snapshot.paramMap.get('slug');
        this.getBlogPost(this.blogID);
    }


    getBlogPost(postSlug) {
        // We cannot pass null types to angular router, so
        // we test for a null string
        if (postSlug !== 'null') {
            this.pageService.getOneBlogPost(postSlug).pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(post => this.setBlogPost(post));
        }
    }

    setBlogPost(post: BlogPost) {
        this.blogPostQ = post;
        this.blogForm.controls.id.setValue(post.id);
        this.blogForm.controls.name.setValue(post.title);
        this.blogForm.controls.wysiwyg.setValue(post.content);
        this.blogForm.controls.tags.setValue(post.formTags);
        if (post.awsURL !== null) {
            this.blogForm.controls.image.setValue('existing');
        }
    }

    removeCurrentImage(postID) {
        // We need to remove the image, send request to API
        this.adminBlogService.removeBlogImage(postID).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(response => this.evalAPIResponse(response));
    }

    evalAPIResponse (response) {
        if (response.status === true) {
            this.progress = 0;
            this.success = true;
            this.blogForm.reset();
            this.formError = null;
            this.blogPostQ = null;
            if (this.blogID === 'null') {
                // We added a new post, navigate away
                this.router.navigate(['/admin/list_blog_posts']);
            } else {
                // refresh current view
                this.getBlogPost(this.blogID);
            }
        } else {
            this.formError = response.message;
        }

    }

    savePost() {
        let uploadURL = API_URL;
        if (this.blogID === 'null') {
            uploadURL += '/admin/blog/add_new_post';
        } else {
            uploadURL += '/admin/blog/update_blog_post';
        }

        this.success = false;
        if ( !this.blogForm.valid ) {
            markAllAsDirty(this.blogForm);
            return;
        }

        this.http.post(uploadURL, toFormData(this.blogForm.value), {
            reportProgress: true,
            observe: 'events',
        }).pipe(
            takeUntil(this.ngUnsubscribe),
            uploadProgress(progress => (this.progress = progress)),
            toResponseBody()
        ).subscribe(res => {
            this.evalAPIResponse(res);
        });
    }

}
